.uptime-tooltip {}

.uptime-tooltip .pointer-container {
  position: fixed;
  height: 10px;
  top: -10px;
  z-index: 1001
}

.uptime-tooltip .pointer-smaller,
.uptime-tooltip .pointer-larger {
  bottom: 0;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.uptime-tooltip .pointer-smaller {
  border: 8px solid transparent;
  border-bottom-color: white;
  margin-left: 1px;
  margin-bottom: -1px
}

.uptime-tooltip .pointer-larger {
  border: 9px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.1)
}

.uptime-tooltip .tooltip-box {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 3px;
  position: fixed;
  width: 320px;
  margin-left: -160px;
  padding: 15px;
  z-index: 2
}